import { gql, useMutation } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";

const SET_BETA_MODE_MUTATION = gql`
  mutation SetBetaMode($enabled: Boolean!) {
    setMyPublicareBetaMode(enabled: $enabled)
  }
`;

export const isCookieSet = () => {
  return (
    new RegExp("^publicareBetaMode=1", "i").test(document?.cookie || "") ||
    new RegExp("[^_]publicareBetaMode=1", "i").test(document?.cookie || "")
  );
};

const useBetaMode = () => {
  const [isBetaModeEnabled, setIsBetaModeEnabled] = useState(false);
  const [setBetaMode] = useMutation(SET_BETA_MODE_MUTATION);

  useEffect(() => {
    const enabled = isCookieSet();
    setIsBetaModeEnabled(enabled);
  }, [setIsBetaModeEnabled]);

  const toggleBetaMode = useCallback(async () => {
    await setBetaMode({
      variables: {
        enabled: !isBetaModeEnabled,
      },
    });
    setIsBetaModeEnabled(!isBetaModeEnabled);
    return !isBetaModeEnabled;
  }, [isBetaModeEnabled, setBetaMode]);

  return {
    toggleBetaMode,
    isBetaModeEnabled,
  };
};

export default useBetaMode;
