import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useRouter } from "next/router";
import Header from "../modules/layout/components/Header";
import Footer from "../modules/layout/components/Footer";

import LoginForm from "../modules/auth/components/LoginForm";
import useCurrentUser from "../modules/auth/hooks/useCurrentUser";
import AlreadyLoggedIn from "../modules/auth/components/AlreadyLoggedIn";
import {
  addApolloState,
  initializeApollo,
} from "../modules/apollo/apolloClient";
import { navigationQueries } from "../modules/ssgHelpers/sharedPageData";
import Loading from "../modules/layout/components/Loading";
import LocalizedHead from "modules/navigation/components/LocalizedHead";
import { findPageIdByFilePath } from "modules/common/utils/findPageIdByFilePath";
import { preFetchPageById } from "modules/navigation/hooks/useCMSPageById";
import useBetaMode from "modules/auth/hooks/useBetaMode";

const Login = ({ currentPage }) => {
  const intl = useIntl();
  const { currentUser, loading } = useCurrentUser();
  const { isBetaModeEnabled } = useBetaMode();
  const router = useRouter();

  const { next = "/" } = router.query || {};

  const alreadyLoggedIn = !loading && currentUser;

  useEffect(() => {
    if (alreadyLoggedIn) {
      if (isBetaModeEnabled) {
        document.location = `/${intl.locale}${next as string}`;
      } else {
        router.push(next as string);
      }
    }
  }, [alreadyLoggedIn]);

  if (alreadyLoggedIn && next !== "/") return <Loading />;
  return (
    <>
      <LocalizedHead
        fallbackTitle={currentPage?.title}
        routes={currentPage?._routes}
        {...currentPage}
      />
      <Header />
      <div className="container">
        {alreadyLoggedIn ? (
          <AlreadyLoggedIn
            title={intl.formatMessage({
              id: "login_title",
              defaultMessage: "Login Benutzerkonto Publicare",
            })}
          />
        ) : (
          <LoginForm />
        )}
      </div>
      <Footer />
    </>
  );
};

export async function getStaticProps({ locale }) {
  if (locale === process.env.NEXT_PUBLIC_FAKE_LOCALE) {
    return {
      notFound: true,
    };
  }
  const forgotPasswordPageId = findPageIdByFilePath("/login");
  const apolloClient = await initializeApollo(null, { locale });

  const currentPage = await preFetchPageById(apolloClient, {
    locale,
    pageId: forgotPasswordPageId,
  });
  await navigationQueries(apolloClient, locale);
  return addApolloState(apolloClient, {
    props: { currentPage },
    revalidate: 3600,
  });
}

export default Login;
